import React from "react";

const StudentlibraryCourses=()=>{
return(
    <>
    <p>hello curio StudentlibraryCourses</p>
    </>
);
}

export default StudentlibraryCourses;