import React from "react";

const StudentlibraryFlashcard=()=>{
return(
    <>
    <p>hello curio StudentlibraryFlashcard</p>
    </>
);
}

export default StudentlibraryFlashcard;