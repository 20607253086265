import React from "react";

const Studentflashcard=()=>{
return(
    <>
    <p>hello curio ai</p>
    </>
);
}

export default Studentflashcard;